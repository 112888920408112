<template>
  <div class="relative">
    <loading-spinner
      type="half-circle"
      :duration="1000"
      :size="40"
      color="#4469A8"
      :loading="loading"
    />
    <div class="intro-y box overflow-auto mt-8 sm:mt-0">
      <table ref="tabulator" class="table table-report sm:mt-2">
        <thead>
          <tr>
            <th
              v-for="col in columns"
              :key="col.name"
              class="whitespace-nowrap"
              :class="col.name != 'userName' ? 'text-center' : ''"
            >
              {{ i18n(col.label) }}
            </th>
          </tr>
        </thead>
        <tbody v-if="rows.length">
          <tr v-for="(row, index) in rows" :key="index" class="intro-x zoom-in">
            <td class="text-center flex items-center justify-center">
              <!-- Avatar -->
              <app-list-item-image
                :value="row.avatar"
                type="user"
              ></app-list-item-image>
            </td>
            <td class="">
              <!-- User Name -->
              <router-link
                class="text-theme-10 dark:text-theme-30 cursor-pointer"
                :to="`/${type}s/${row.id}`"
                target="_blank"
              >
                {{ presenter(row, 'fullName') }}
              </router-link>
              <div class="font-medium whitespace-nowrap" v-if="row.email">
                {{ presenter(row, 'email') }}
              </div>
              <div
                class="font-medium whitespace-nowrap"
                style="direction: ltr !important"
                v-else
              >
                {{ presenter(row, 'phoneNumber') }}
              </div>
            </td>
            <td class="text-center">
              <!-- Join Date -->
              <div class="font-medium whitespace-nowrap">
                {{ presenterDate(row, 'createdAt') }}
              </div>
              <!-- <div class="font-small text-xs whitespace-nowrap mt-1">
                {{ presenterTime(row, 'createdAt') }}
              </div> -->
            </td>
          </tr>
        </tbody>
      </table>
      <app-empty-page
        v-if="!rows.length && !loading"
        :label="i18n('empty.client')"
        label-class="text-md font-medium"
        icon-size="3x"
        class="box w-full py-24"
      ></app-empty-page>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import { UserModel } from '@/store/user/user-model'
const { fields } = UserModel

export default defineComponent({
  name: 'home-latest-users-table',
  props: ['type'],
  setup() {
    const tabulator = ref()
    const reInitOnResizeWindow = () => {
      // Redraw table onresize
      window.addEventListener('resize', () => {
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      tabulator
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'avatar',
          field: 'avatar',
          label: 'iam.fields.avatar',
          align: 'center'
        },
        {
          name: 'userName',
          field: 'userName',
          label: `iam.fields.${this.type}`,
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'iam.fields.joinDate',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      latestUsers: 'user/list/latestUsers',
      loading: 'user/list/loading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    rows() {
      return this.latestUsers[this.type]
    },
    fields() {
      return fields
    }
  },
  async mounted() {
    await this.doFetchlatestUsers()
  },
  methods: {
    ...mapActions({
      doFetch: 'user/list/doFetch'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} ريال` : `${PRICE} SAR`
    },
    presenter(row, fieldName) {
      return UserModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options
      const val = options.filter(el => el.id == row[fieldName])
      return val.length ? val[0].label : 'ـــ'
    },
    async doFetchlatestUsers() {
      await this.doFetch({
        type: this.type,
        filter: [],
        pagination: {
          firstPage: true,
          limit: 4,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
