<template>
  <div>
    <div class="report-box zoom-in" style="cursor: unset !important">
      <loading-spinner
        type="atom"
        :duration="1000"
        :size="40"
        color="#4469A8"
        :loading="loading"
      />
      <div class="box p-5">
        <div class="flex">
          <slot name="icon"></slot>
          <div :class="isRTL ? 'mr-auto' : 'ml-auto'">
            <!-- <Tippy tag="div" class="report-box__indicator bg-theme-10 cursor-pointer" :content="i18n('dashboard.higherThanLastMonth')" v-if="indicatorUp">
              {{ percentage }}% <ChevronUpIcon class="w-4 h-4 ml-0.5" />
            </Tippy>
            <Tippy tag="div" class="report-box__indicator bg-theme-24 cursor-pointer" :content="TippyDownContent" v-if="indicatorDown">
              {{ percentage }}% <ChevronDownIcon class="w-4 h-4 ml-0.5" />
            </Tippy> -->
          </div>
        </div>
        <div class="text-3xl font-medium leading-8 mt-6">
          {{ count && countWithCommas }}
        </div>
        <div class="text-base text-gray-600 mt-1 dark:text-gray-300">
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HelperClass from '@/shared/helperClass'

export default {
  name: 'report-box',
  props: {
    label: {
      type: String,
      default: 'Report Title'
    },
    count: {
      type: Number,
      default: 0
    },
    countInLastMonth: {
      type: Number,
      default: 0
    },
    background: {
      type: String,
      // default: '#E3F8FA',
      required: false
    },
    color: {
      type: String,
      // default: 'rgba(28, 28, 28, 0.8)',
      required: false
    }
  },
  computed: {
    ...mapGetters({
      loading: 'dashboard/loading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    countWithCommas() {
      return HelperClass.numberWithCommas(Math.round(this.count))
    },
    percentage() {
      let percent = 0
      if (this.count > this.countInLastMonth) {
        const x = this.count - this.countInLastMonth
        const y = this.count
        percent = x / y
      }
      if (this.count < this.countInLastMonth) {
        const x = this.countInLastMonth - this.count
        const y = this.countInLastMonth
        percent = x / y
      }
      percent *= 100
      return (Math.round(percent * 100) / 100).toFixed(2)
    },
    indicatorDown() {
      return this.count < this.countInLastMonth
    },
    indicatorUp() {
      return this.count > this.countInLastMonth
    },
    TippyUpContent() {
      return this.percentage + '% ' + this.i18n('dashboard.higherThanLastMonth')
    },
    TippyDownContent() {
      return this.percentage + '% ' + this.i18n('dashboard.lowerThanLastMonth')
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style scoped>
.report-box__indicator {
  font-weight: 700 !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
</style>
