/* eslint-disable */

import { i18n } from '@/locales/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import IdField from '@/shared/fields/id-field';
import StringField from '@/shared/fields/string-field';
import DateTimeField from '@/shared/fields/date-time-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import DecimalField from '@/shared/fields/decimal-field';
import JsonField from '@/shared/fields/json-field';
import IntegerField from '@/shared/fields/integer-field';
// import StringArrayField from '@/shared/fields/string-array-field';
// import BooleanField from '@/shared/fields/boolean-field';
// import ImagesField from '@/shared/fields/images-field';
// import DateTimeRangeField from '@/shared/fields/date-time-range-field';
// import GenericField from '@/shared/fields/generic-field';

function label(name) {
  return i18n(`order.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`order.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id'), { required: true }),
  orderNo: new IntegerField('orderNo', label('orderNo')),
  amount: new DecimalField('amount', label('amount')),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), { required: false }),
  buyerName: new StringField('buyerName', label('buyerName'), { required: false }),
  sellerName: new StringField('sellerName', label('sellerName'), { required: false }),
  deliveryCompanyName: new StringField('deliveryCompanyName', label('deliveryCompanyName'), { required: false }),
  promoCode: new StringField('promoCode', label('promoCode'), { required: false }),
  QRString: new StringField('QRString', label('QRString'), { required: false }),
  deliveryPolicy: new StringField('deliveryPolicy', label('deliveryPolicy'), { required: false }),
  deliveryVideo: new StringField('deliveryVideo', label('deliveryVideo'), { required: false }),
  receivingVideo: new StringField('receivingVideo', label('receivingVideo'), { required: false }),
  estimatedDeliveryTimeStamp: new DateTimeField('estimatedDeliveryTimeStamp', label('estimatedDeliveryTimeStamp')),

  orderId: new StringField('orderId', label('orderId'), { required: false }),
  buyerId: new StringField('buyerId', label('buyerId'), { required: false }),
  sellerId: new StringField('sellerId', label('sellerId'), { required: false }),
  addressId: new StringField('addressId', label('addressId'), { required: false }),
  delvieryCompanyId: new StringField('delvieryCompanyId', label('delvieryCompanyId'), { required: false }),

  status: new EnumeratorField('status', label('status'), [
    { id: 'shadow', label: enumeratorLabel('status', 'shadow') },
    { id: 'pending', label: enumeratorLabel('status', 'pending') },
    { id: 'inProgress', label: enumeratorLabel('status', 'inProgress') },
    { id: 'delivering', label: enumeratorLabel('status', 'delivering') },
    { id: 'delivered', label: enumeratorLabel('status', 'delivered') },
    { id: 'closed', label: enumeratorLabel('status', 'closed') },
    { id: 'cancelled', label: enumeratorLabel('status', 'cancelled') },
    { id: 'requestReturn', label: enumeratorLabel('status', 'requestReturn') },
    { id: 'returnInProgress', label: enumeratorLabel('status', 'returnInProgress') },
    { id: 'returnDelivering', label: enumeratorLabel('status', 'returnDelivering') },
  ]),
  paymentMethod: new EnumeratorField('paymentMethod', label('paymentMethod'), [
    { id: 'visa', label: enumeratorLabel('paymentMethod', 'visa') },
    { id: 'wallet', label: enumeratorLabel('paymentMethod', 'wallet') },
  ]),
  deliveryType: new EnumeratorField('deliveryType', label('deliveryType'), [
    { id: 'delivery', label: enumeratorLabel('deliveryType', 'delivery') },
    { id: 'pickup', label: enumeratorLabel('deliveryType', 'pickup') },
  ]),
  
  address: new JsonField('address', label('address'), ['id', 'name', 'description', 'lat', 'long', 'phoneNumber', 'isDefault']),
  pickUpAddress: new JsonField('pickUpAddress', label('pickUpAddress'), ['id', 'name', 'description', 'lat', 'long', 'phoneNumber', 'isDefault']),
  orderStepper: new JsonField('orderStepper', label('orderStepper'), ['title', 'description', 'time', 'reached']),
  product: new JsonField('product', label('product')),
  buyer: new JsonField('buyer', label('buyer')),
  seller: new JsonField('seller', label('seller')),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdBy: new StringField('createdBy', label('createdBy')),
  updatedBy: new StringField('updatedBy', label('updatedBy')),
  // createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class OrderModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
