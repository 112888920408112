<template>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
      <report-box
        :label="i18n('dashboard.ordersCount')"
        :count="model.ordersCount"
        :countInLastMonth="doFindValueOfLastMonth('ordersCount')"
      >
        <template v-slot:icon>
          <ClipboardIcon
            class="report-box__icon text-theme-21"
            :style="isRTL ? 'transform: scaleX(-1)' : ''"
          />
        </template>
      </report-box>
    </div>
    <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
      <report-box
        :label="i18n('dashboard.ordersAmount')"
        :count="model.ordersAmount"
        :countInLastMonth="doFindValueOfLastMonth('ordersAmount')"
      >
        <template v-slot:icon>
          <!-- <DollarSignIcon class="report-box__icon text-theme-10" /> -->
          <span
            class="report-box__icon text-theme-10 flex items-center text-xl"
            >{{ isRTL ? 'ريال' : 'SAR' }}</span
          >
        </template>
      </report-box>
    </div>
    <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
      <report-box
        :label="i18n('dashboard.chefsCount')"
        :count="model.chefsCount"
        :countInLastMonth="doFindValueOfLastMonth('chefsCount')"
      >
        <template v-slot:icon>
          <img
            src="@/assets/images/chef.png"
            class="report-box__icon"
            style="
              filter: invert(69%) sepia(80%) saturate(1164%) hue-rotate(2deg)
                brightness(104%) contrast(102%);
            "
          />
        </template>
      </report-box>
    </div>
    <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
      <report-box
        :label="i18n('dashboard.captainsCount')"
        :count="model.captainsCount"
        :countInLastMonth="doFindValueOfLastMonth('captainsCount')"
      >
        <template v-slot:icon>
          <img
            src="@/assets/images/captainIcon.png"
            class="report-box__icon"
            style="
              filter: invert(52%) sepia(97%) saturate(5494%) hue-rotate(216deg)
                brightness(88%) contrast(92%);
            "
          />
        </template>
      </report-box>
    </div>
    <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
      <report-box
        :label="i18n('dashboard.clientsCount')"
        :count="model.clientsCount"
        :countInLastMonth="doFindValueOfLastMonth('captainsCount')"
      >
        <template v-slot:icon>
          <UsersIcon
            class="report-box__icon text-theme-21"
            :style="isRTL ? 'transform: scaleX(-1)' : ''"
          />
        </template>
      </report-box>
    </div>
    <div class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
      <report-box
        :label="i18n('dashboard.mealsCount')"
        :count="model.mealsCount"
        :countInLastMonth="doFindValueOfLastMonth('captainsCount')"
      >
        <template v-slot:icon>
          <svg
            class="report-box__icon text-theme-21"
            :style="isRTL ? 'transform: scaleX(-1)' : ''"
            xmlns="http://www.w3.org/2000/svg"
            width="512"
            height="512"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="m445.588 56l-.026 384.352c6.881 11.323 14 15.677 19.97 15.648c5.924-.028 12.967-4.434 19.714-15.418L466.82 244.27l-.215-2.391l1.475-1.906c21.174-27.169 28.573-74.108 22.533-113.81c-3.02-19.852-9.342-37.82-18.195-50.522c-7.424-10.652-16.28-17.447-26.828-19.641h-.002zm-372.375.004l-.016 67.127l-12.56-.016V56.008H46.332l.002 67.11H33.756v-67.11h-14.57v103.228c-.001 11.417 6.23 17.748 16.04 21.662l4.06 1.622l-.09 4.37c-2 84.57-3.977 169.139-5.962 253.708C40.074 451.79 47.1 456.028 52.95 456c5.85-.028 12.87-4.377 19.623-15.432c-2.315-84.547-4.63-169.096-6.941-253.644l-.12-4.4l4.073-1.606c10.324-4.106 17.039-11.074 17.039-21.676V56.004h-13.41zM256 95A161 161 0 0 0 95 256a161 161 0 0 0 161 161a161 161 0 0 0 161-161A161 161 0 0 0 256 95z"
            />
          </svg>
        </template>
      </report-box>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ReportBox from '@/views/dashboard/report-box.vue'

export default {
  name: 'app-home-general-report',
  components: {
    [ReportBox.name]: ReportBox
  },
  computed: {
    ...mapGetters({
      model: 'dashboard/statistics',
      loading: 'dashboard/loading',
      currentDate: 'dashboard/currentDate',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    })
    // ordersCount() {
    //   return this.doFindValueOfThisMonth('ordersCount')
    // },
    // ordersAmount() {
    //   return this.doFindValueOfThisMonth('ordersAmount')
    // },
    // chefsCount() {
    //   return this.doFindValueOfThisMonth('chefsCount')
    // },
    // captainsCount() {
    //   return this.doFindValueOfThisMonth('captainsCount')
    // }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    },
    doFindValueOfThisMonth(fieldName) {
      const node = this.model ? this.model[fieldName] : null
      const year = this.currentDate ? this.currentDate.year : 0
      const month = this.currentDate ? this.currentDate.month : 0

      if (node && year && month) {
        const yearNode = node[year] || {}
        const monthNode = yearNode[month] || {}
        return monthNode.value || 0
      }
      return 0
    },
    doFindValueOfLastMonth(fieldName) {
      const node = this.model ? this.model[fieldName] : null
      const currentYear = this.currentDate ? this.currentDate.year : 0
      const currentMonth = this.currentDate ? this.currentDate.month : 0
      let month = 0
      let year = 0

      if (currentYear && currentMonth) {
        const lastMonth = currentMonth - 1
        month = lastMonth > 0 ? lastMonth : 12
        year = lastMonth > 0 ? currentYear : currentYear - 1
      }

      if (node && year && month) {
        const yearNode = node[year] || {}
        const monthNode = yearNode[month] || {}
        return monthNode.value || 0
      }
      return 0
    }
  }
}
</script>

<style scoped></style>
