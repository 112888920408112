<template>
  <div class="relative">
    <loading-spinner
      type="half-circle"
      :duration="1000"
      :size="40"
      color="#4469A8"
      :loading="loading"
    />
    <div class="intro-y box overflow-auto mt-8 sm:mt-0">
      <table ref="tabulator" class="table table-report sm:mt-2">
        <thead>
          <tr>
            <th
              v-for="col in columns"
              :key="col.name"
              class="text-center whitespace-nowrap"
            >
              {{ i18n(col.label) }}
            </th>
          </tr>
        </thead>
        <tbody v-if="rows.length">
          <tr
            v-for="(row, index) in rows"
            :key="index"
            class="intro-x"
            :class="{ removedRow: row.isRemoved }"
          >
            <td class="w-40 text-center">
              <!-- Order Number -->
              <router-link
                class="text-xs text-theme-10 dark:text-theme-30 whitespace-nowrap"
                :to="`/orders/${row.id}`"
                target="_blank"
              >
                {{ row.orderNo ? `#${row.orderNo}` : 'ـــ' }}
              </router-link>
            </td>
            <td class="text-center">
              <!-- Amount -->
              <div
                class="font-medium whitespace-nowrap text-theme-10 dark:text-theme-30"
              >
                {{ formatPrice(row.totalAmount) }}
              </div>
            </td>
            <td class="text-center">
              <!-- Date -->
              <div class="font-medium text-xs whitespace-nowrap">
                {{ presenterDate(row, 'createdAt') }}
              </div>
              <div class="font-small text-xs whitespace-nowrap mt-1">
                {{ presenterTime(row, 'createdAt') }}
              </div>
            </td>
            <td class="text-center">
              <!-- Status -->
              <div
                class="flex items-center justify-center whitespace-nowrap"
                :class="{
                  'text-gray-600': row.status == 'inProgress',
                  'text-theme-24': row.status == 'cancelled',
                  'text-theme-10': row.status == 'delivering',
                  'text-theme-10': row.status == 'closed'
                }"
              >
                {{ i18n(`order.enumerators.status.${row.status}`) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <app-empty-page
        v-if="!rows.length && !loading"
        :label="i18n('empty.order')"
        label-class="text-md font-medium"
        icon-size="3x"
        class="box w-full py-24"
      ></app-empty-page>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineComponent, ref, onMounted } from 'vue'
import feather from 'feather-icons'
import { GenericModel } from '@/shared/model/generic-model'
import { OrderModel } from '@/store/order/order-model'
const { fields } = OrderModel

export default defineComponent({
  name: 'home-latest-orders-table',
  setup() {
    const tabulator = ref()
    const reInitOnResizeWindow = () => {
      // Redraw table onresize
      window.addEventListener('resize', () => {
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    onMounted(() => {
      reInitOnResizeWindow()
    })

    return {
      tabulator
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'orderNumber',
          field: 'orderNumber',
          label: 'order.fields.orderNumber',
          align: 'center'
        },
        {
          name: 'amount',
          field: 'amount',
          label: 'order.fields.price',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'dashboard.newestOrders.table.orderDate',
          align: 'center'
        },
        {
          name: 'status',
          field: 'status',
          label: 'order.fields.status',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      rows: 'order/list/rows',
      loading: 'order/list/loading',
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode'
    }),
    fields() {
      return fields
    }
  },
  async mounted() {
    await this.doFetchlatestOrders()
  },
  methods: {
    ...mapActions({
      doFetch: 'order/list/doFetch'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} ريال` : `${PRICE} SAR`
    },
    presenter(row, fieldName) {
      return OrderModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    presenterTime(row, fieldName) {
      return GenericModel.presenterTime(row, fieldName)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options
      const val = options.filter(el => el.id == row[fieldName])
      return val.length ? val[0].label : 'ـــ'
    },
    async doFetchlatestOrders() {
      await this.doFetch({
        filter: [],
        pagination: {
          firstPage: true,
          limit: 4,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}
.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
