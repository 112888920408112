<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 2xl:col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div class="intro-y flex items-center h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              {{ i18n('dashboard.generalReport') }}
            </h2>
            <a
              href="#"
              @click="reloadData"
              class="flex items-center text-theme-26 dark:text-theme-30"
              :class="isRTL ? 'mr-auto' : 'ml-auto'"
            >
              <RefreshCcwIcon class="w-4 h-4 mr-3 ml-3" />
              {{ i18n('dashboard.reloadData') }}
            </a>
          </div>
          <app-home-general-report></app-home-general-report>
        </div>
        <!-- END: General Report -->

        <!-- BEGIN: Sales Report -->
        <div class="col-span-12 lg:col-span-6 mt-8">
          <!-- <home-sales-report-chart></home-sales-report-chart> -->
        </div>
        <!-- END: Sales Report -->

        <!-- BEGIN: Weekly Top Cities -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
          <!-- <home-weekly-top-cities-chart ref="weeklyTopCities" class="h-full"></home-weekly-top-cities-chart> -->
        </div>
        <!-- END: Weekly Top Cities -->

        <!-- BEGIN: Monthly Top Cities -->
        <div class="col-span-12 sm:col-span-6 lg:col-span-3 mt-8">
          <!-- <home-monthly-top-cities-chart ref="monthlyTopCities" class="h-full"></home-monthly-top-cities-chart> -->
        </div>
        <!-- END: Monthly Top Cities -->

        <!-- BEGIN: Latest Orders Table -->
        <div class="col-span-12 lg:col-span-6 mt-6">
          <div class="intro-y block flex items-center justify-between h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              {{ i18n('dashboard.newestOrders.title') }}
            </h2>
            <router-link
              to="/orders"
              class="text-sm font-medium underline text-theme-10 dark:text-theme-30"
            >
              {{ i18n('common.seeAll') }}
            </router-link>
          </div>
          <home-latest-orders-table ref="orders"></home-latest-orders-table>
        </div>
        <!-- END: Latest Orders Table -->

        <!-- BEGIN: Latest Users Table -->
        <div class="col-span-12 lg:col-span-6 mt-6">
          <div class="intro-y block flex items-center justify-between h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              {{ i18n('dashboard.newestChefs.title') }}
            </h2>
            <router-link
              to="/chefs"
              class="text-sm font-medium underline text-theme-10 dark:text-theme-30"
            >
              {{ i18n('common.seeAll') }}
            </router-link>
          </div>
          <home-latest-users-table
            ref="users"
            type="chef"
          ></home-latest-users-table>
        </div>
        <div class="col-span-12 lg:col-span-6 mt-6">
          <div class="intro-y block flex items-center justify-between h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              {{ i18n('dashboard.newestCaptains.title') }}
            </h2>
            <router-link
              to="/captains"
              class="text-sm font-medium underline text-theme-10 dark:text-theme-30"
            >
              {{ i18n('common.seeAll') }}
            </router-link>
          </div>
          <home-latest-users-table
            ref="users"
            type="captain"
          ></home-latest-users-table>
        </div>
        <div class="col-span-12 lg:col-span-6 mt-6">
          <div class="intro-y block flex items-center justify-between h-10">
            <h2 class="text-lg font-medium truncate mr-5">
              {{ i18n('dashboard.newestClients.title') }}
            </h2>
            <router-link
              to="/clients"
              class="text-sm font-medium underline text-theme-10 dark:text-theme-30"
            >
              {{ i18n('common.seeAll') }}
            </router-link>
          </div>
          <home-latest-users-table
            ref="users"
            type="client"
          ></home-latest-users-table>
        </div>
        <!-- END: Latest Users Table -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineComponent, ref, provide } from 'vue'

import HomeGeneralReport from '@/views/dashboard/home-general-report.vue'
import HomeLatestOrdersTable from '@/views/dashboard/home-latest-orders-table.vue'
import HomeLatestUsersTable from '@/views/dashboard/home-latest-users-table.vue'
// import HomeSalesReportChart from '@/views/dashboard/home-sales-report-chart.vue'
// import HomeWeeklyTopCitiesChart from '@/views/dashboard/home-weekly-top-cities-chart.vue'
// import HomeMonthlyTopCitiesChart from '@/views/dashboard/home-monthly-top-cities-chart.vue'

export default defineComponent({
  components: {
    [HomeGeneralReport.name]: HomeGeneralReport,
    [HomeLatestOrdersTable.name]: HomeLatestOrdersTable,
    [HomeLatestUsersTable.name]: HomeLatestUsersTable
    // [HomeSalesReportChart.name]: HomeSalesReportChart,
    // [HomeWeeklyTopCitiesChart.name]: HomeWeeklyTopCitiesChart,
    // [HomeMonthlyTopCitiesChart.name]: HomeMonthlyTopCitiesChart
  },
  setup() {
    const dateRange = ref('')
    const salesReportFilter = ref()
    const importantNotesRef = ref()

    provide('bind[importantNotesRef]', (el) => {
      importantNotesRef.value = el
    })

    const prevImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('prev')
    }
    const nextImportantNotes = () => {
      const el = importantNotesRef.value
      el.tns.goTo('next')
    }

    return {
      dateRange,
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentDate: 'dashboard/currentDate',
      loading: 'dashboard/loading',
      model: 'dashboard/statistics'
    })
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.dashboard')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.dashboard')
    }
  },
  methods: {
    ...mapActions({
      doFetch: 'dashboard/doFetch'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    goToUser() {
      this.$router.push('/user-view-page')
    },
    goToChef() {
      this.$router.push('/chef-view-page')
    },
    async reloadData() {
      this.doFetch()
      this.$refs.orders.doFetchlatestOrders()
      this.$refs.users.doFetchlatestUsers()
    }
  }
})
</script>

<style scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
